html,
body,
p,
h1,
h2,
h3 {
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: 0;
  outline: 0;
  background-color: transparent;
}

ul {
  list-style-type: none;
}

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif, system-ui;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Vollkorn", serif, system-ui;

  & * {
    font-family: "Vollkorn", serif, system-ui;
  }
}

@media screen and (max-device-width: 800px){
  body{
    -webkit-text-size-adjust: 100%;
  }
}
