$export_horoscope-compatibility-select-image-size: 30;
$export_horoscope-desktop-right-section: 503;
$export_horoscope-desktop-left-section: 720;
$export_horoscope-mobile-image-background-width: 355;
$export_horoscope-tablet-max-width: 1240;
$export_mobile-view-max-width: 740;
$export_small-mobile-view-max-width: 376;
$export_contact-us-tablet-max-width: 1270;
$export_common-tablet-max-width: 950;
$export_common-page-max-width: 1200;
$export_small-page-max-width: 980;
$export_mystery-link-side-size: 185;
$export_mystery-link-side-size-mobile: 114;
$export_mystery-link-gap: 20;
$export_mystery-link-gap-mobile: 5;

/* header */
$mobile-header-content-height: 26px;
$mobile-header-navbar-width: 310px;
$mobile-header-navbar-translate-duration: 300ms;
$mobile-header-height: 45px;
$mobile-header-divided-border-height: 5px;
$mobile-header-hamburger-interaction-height: 110px;
$header-navbar-changes-max-width: 1050px;
$header-top-bar-max-width: 950px;
$header-top-bar-right-offset: 51px;
$header-max-width: 1440px;

$mobile-header-top-bar-height: 40px;
$mobile-header-main-height: 55px;
$mobile-header-quick-height: 60px;

/* footer */
$footer-max-width: 1440px;
$copyright-max-width: 1340px;

/* common */
$mobile-view-max-width: $export_mobile-view-max-width * 1px;
$small-mobile-view-max-width: $export_small-mobile-view-max-width * 1px;
$max-width-1280: 1280px;
$medium-screen-width: 1320px;
$common-tablet-max-width: $export_common-tablet-max-width * 1px;
$medium-validation-mark-size: 25px;
$common-page-max-width: $export_common-page-max-width * 1px;
$small-page-max-width: $export_small-page-max-width * 1px;
$mystery-link-side-size: $export_mystery-link-side-size * 1px;
$mystery-link-side-size-mobile: $export_mystery-link-side-size-mobile * 1px;
$mystery-link-gap: $export_mystery-link-gap * 1px;
$mystery-link-gap-mobile: $export_mystery-link-gap-mobile * 1px;

/* horoscope signs */
$horoscope-desktop-right-section: $export_horoscope-desktop-right-section * 1px;
$horoscope-desktop-left-section: $export_horoscope-desktop-left-section * 1px;
$horoscope-mobile-image-background-width: $export_horoscope-mobile-image-background-width * 1px;
$horoscope-tablet-view-max-width: 900px;
$horoscope-tablet-horizontal-padding: 45px;
$horoscope-tablet-max-width: $export_horoscope-tablet-max-width * 1px;
$horoscope-compatibility-select-image-size: $export_horoscope-compatibility-select-image-size * 1px;
$horoscope-features-vertical-gap: 10px;
$horoscope-features-horizontal-gap: $horoscope-features-vertical-gap;
$horoscope-features-vertical-gap-tablet: 8px;
$horoscope-features-horizontal-gap-tablet: $horoscope-features-vertical-gap;
$horoscope-features-first-line-small-el-height: 130px;
$horoscope-features-first-line-small-el-height-tablet: 112px;
$horoscope-features-second-line-el-height: 300px;
$horoscope-features-second-third-line-el-height-tablet: 283px;
$horoscope-features-third-line-el-height: 300px;
$horoscope-features-forth-line-el-height-mobile: 164px;
$horoscope-features-tablet-max-width: 675px;

/* Contact Us */
$contact-us-tablet-max-width: $export_contact-us-tablet-max-width * 1px;
$contact-us-feedback-form-desktop-width: 350px;
$contact-us-mobile-vertical-padding: 15px;
$contact-us-get-in-touch-content-top-offset: 20px;
$contact-us-block-tablet-width: calc(#{$contact-us-feedback-form-desktop-width} - 5%);

/* Homepage */
$homepage-psychics-grid-gap: 40px;
$homepage-psychics-grid-gap-mobile: 10px;

/* z-index */
$min-z: 1;
$header-z: 5;
$scroll-top: 9;
$nav-bar-z: 10;
$header-search-box-z: 15;
$header-collapsed-item-z: 20;
$sticky-offer-z: 25;
$chat-to-agent-items-z: 30;
$mobile-header-z: 40;
$mobile-header-background-z: 45;
$mobile-header-nav-items-z: 46;
$pop-up-window-z: 50;
$pop-up-window-background-z: 51;
$loader-z: 55;
$loader-background-z: 56;
$iframe-overlay-z: 100;

/* Color */
$primary-color-black: #3c3935; 
$primary-color-white: #fff;
$secondary-color-orange: #f18536;
$primary-color-blue: #0099a8;
$available-color: #48AE7E;
$busy-color: #bb5204;
$offline-color: #858585;
$primary-color-green: #1c573d;

:export {
  mobileViewMaxWidth: $export_mobile-view-max-width;
  smallMobileViewMaxWidth: $export_small-mobile-view-max-width;
  horoscopeCompatibilitySelectImageSize: $export_horoscope-compatibility-select-image-size;
  horoscopeDesktopRightSection: $export_horoscope-desktop-right-section;
  horoscopeDesktopLeftSection: $export_horoscope-desktop-left-section;
  horoscopeTabletMaxWidth: $export_horoscope-tablet-max-width;
  horoscopeMobileImageBackgroundWidth: $export_horoscope-mobile-image-background-width;
  contactUsTabletMaxWidth: $export_contact-us-tablet-max-width;
  commonTabletMaxWidth: $export_common-tablet-max-width;
  commonPageMaxWidth: $export_common-page-max-width;
  smallPageMaxWidth: $export_small-page-max-width;
  mysteryLinkSideSize: $export_mystery-link-side-size;
  mysteryLinkSideSizeMobile: $export_mystery-link-side-size-mobile;
  mysteryLinkGap: $export_mystery-link-gap;
  mysteryLinkGapMobile: $export_mystery-link-gap-mobile;
}
